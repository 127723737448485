import Breadcrumbs from '@/components/breadcrumbs/index.vue';
import mainSelect from '@/components/main-select/index.vue'
import secondaryButton from '@/components/buttons/secondary-button/index.vue'
import Autocomplete from '../../components/misc/autocomplete/index.vue'
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {ModelListSelect} from "vue-search-select";


export default {
  name: 'professional-registration',
  data() {
    return {
      onlyUkraine:[],
      cityCollection: [],
      statesCollection: [],
      countriesResult: [],
      countriesCollection: [],
      searchCountryTitle: '',
      activeItemRepair: false,
      checked: [],
      types: [],
      passwordType: 'text',
      listCountries: [],
      activeItem: null,
      validationFiles: false,
      selectedCountry: {
        category: '',
        type: {
          name: 'Украина',
          key: 'default'
        }
      },
      selectedCity: {
        category: '',
        type: {
          name: 'Киев',
          key: 'default'
        }
      },
      payload: {
        email: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        phone: '',
        phoneViber: '',
        phoneTelegram: '',
        product_id: '',
        count: 1,
        activeItem: null,
        files: [],
        country: '',
        city: '',
        state: '',
        address: '',
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      email: {
        required,
        maxLength: maxLength(50),
        email
      },
      phone: {
        required,
        minLength: minLength(19)
      },
      first_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20)
      },

      middle_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20)
      },

    },
  },
  watch: {
    'searchCountryTitle'() {
      // this.search()
    },
    'payload.files'(newVal) {
      if (newVal) {
        if (this.payload.files.length) {
          this.validationFiles = false
        }
      }
    },
    'payload.country'(newVal) {
      if (newVal.length) {
        this.fetchState(this.payload.country).then(() => {
          this.statesCollection = this.state
        })
      }


    },
    'payload.state'() {
      let {country, state} = this.payload;
      this.fetchTowns({country, state}).then(() => {
        this.cityCollection = this.citiesList
      })
    }
  },

  mounted() {
    this.fetchCountries().then(()=>{
      console.log(this.countries,'countries')
      let ukraine = this.countries.find(el=>el.name==='Украина')
      this.onlyUkraine.push(ukraine)
    })
    // this.activeItem = this.title[0]
  },
  methods: {
    ...mapActions({
      fetchCountries: 'auth/GET_COUNTRIES',
      fetchTowns: 'auth/GET_TOWNS',
      register: 'auth/REGISTRATION_PROFESSIONAL',
      fetchState: 'auth/GET_STATE'
    }),

    ...mapMutations({
      profFakeAuth: 'header/CHANGE_FAKE_PROF_AUTH',
      changePopupRegistration: `card/CHANGE_POPUP_REGISTRATION`,

    }),
    printSearchText(searchText) {
      this.searchCountryTitle = searchText
    },
    selectLocation(name) {
      this.payload.country = name
    },
    clearSearch() {
      this.searchCountryTitle = ''
    },
    redirectLogin() {
      this.$router.push('/');
      this.changePopupRegistration(true)

    },
    handleFileChange(evt) {
      let arr = Array.from(evt.target.files)
      this.payload.files = this.payload.files.concat(arr)
    },
    removeFile(file) {
      const index = this.payload.files.findIndex(e => e.name === file.name)
      this.payload.files.splice(index, 1)
    },
    setFakeProfAuth() {
      const parsed = JSON.stringify(['profFakeAuth']);
      localStorage.setItem('profFakeAuth', parsed);
      this.profFakeAuth(['profFakeAuth'])
      setTimeout(function () {
        window.location.reload()
      }, 800);
      this.$router.push({name: 'professional-data'}).catch(() => {
      });
    },
    showItemRepair() {
      this.activeItemRepair = !this.activeItemRepair
    },
    select() {
    },
    setPasswordType() {
      this.passwordType === 'text' ? this.passwordType = 'password' : this.passwordType = 'text';
    },
    setActive(value) {
      this.activeItem = value
    },
    closePopup() {
      this.$emit('close-popup');
    },
    parsFormData(obj) {
      let fd = new FormData();
      for (let i in obj) {
        if (i === 'files') {
          for (let j in obj[i]) {
            fd.append(`files[${j}]`, obj[i][j]);
          }
        } else {
          fd.append(i, obj[i])
        }
      }
      for (var value of fd.values()) {

      }
      return fd
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let obj = {};
        obj.email_registration = this.payload.email;
        obj.first_name = this.payload.first_name;
        obj.last_name = this.payload.last_name;
        obj.middle_name = this.payload.middle_name;
        obj.phone = this.payload.phone;
        obj.viber = this.payload.phoneViber;
        obj.telegram = this.payload.phoneTelegram;
        obj.country = this.payload.country
        obj.city = this.payload.city
        obj.address = this.payload.address
        obj.type = 'professional';
        obj.files = this.payload.files
        obj.state = this.payload.state
        this.register(this.parsFormData(obj))
          .then(() => {
            this.$toasted.success(this.$t('successCreateProfessional'));
            this.$router.push({name: 'home'}).catch(() => {
            })
          }).catch(e => {
          console.log('');
          if (e.response.status === 418) {
            this.$toasted.errcitiesListor(e.response.data.error.message);
          }
          if (e.response.status === 422) {
            this.$toasted.error(this.$t('emailAlreadyTaken'));
          }
        });
      }
    },

  },
  created() {
    // this.fetchState(this.payload.country)

  },
  destroyed() {

  },
  computed: {
    ...mapGetters({
      countries: 'auth/countriesRegistration',
      citiesList: 'auth/townsRegistration',
      state: 'auth/state'
    }),

    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 50));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('validationLengthPhone'));
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    firstNameErrors() {
      let error = [];
      if (!this.$v.payload.first_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.first_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.first_name.minLength) {
        error.push(this.$t('validationMin').replace(':count', 3));
      }
      if (!this.$v.payload.first_name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (this.validationErrors.first_name) {
        this.validationErrors.first_name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    middleNameErrors() {
      let error = [];
      if (!this.$v.payload.middle_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.middle_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.middle_name.minLength) {
        error.push(this.$t('validationMin').replace(':count', 3));
      }
      if (!this.$v.payload.middle_name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (this.validationErrors.middle_name) {
        this.validationErrors.middle_name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  components: {
    Breadcrumbs,
    mainSelect,
    secondaryButton,
    Autocomplete,
    ModelListSelect
  }
}
